<template>
  <section>
    <b-link
      class="action-link"
      v-if="_.isEmpty(this.circleData)"
      @click="onShowModal"
    >
      {{ $t("MAP.SELECT_COORDS_AND_RADIUS") }}
    </b-link>

    <b-link class="action-link" v-else @click="onShowModal">
      {{ $t("MAP.CHANGE") }}: {{ renderData }}
    </b-link>

    <b-modal size="lg" class="modal--widget" ref="my-modal">
      <template v-slot:modal-header="{ close, title }">
        <h5>{{ $t("MAP.CHANGE_POINT_AND_RADIUS") }}</h5>
        <b-button-close @click="close" class="button--close" />
      </template>

      <GmapMap
        :center="mapCenter"
        :zoom="zoomMap"
        map-type-id="terrain"
        :style="{ width: width, height: height }"
        ref="mapRef"
        :options="{
          streetViewControl: false,
          mapTypeControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: false,
        }"
        @click="onSelectPoint"
        @zoom_changed="updateStore('zoom', $event)"
        @center_changed="updateStore('reportedCenter', $event)"
      >
        <GmapCircle
          v-if="circleData && circleData.coordinates"
          :center.sync="circleData.coordinates"
          :radius="circleData.radius"
          :draggable="true"
          :editable="true"
          @radius_changed="onRadius_changed"
          @center_changed="onCenter_changedCircle"
          :options="{
            strokeColor: '#FF0000',
            strokeOpacity: 0.6,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.05,
          }"
        />
      </GmapMap>

      <template v-slot:modal-footer>
        <div class="w-100">
          <p v-if="renderData" class="float-left">
            {{ renderData }}
            <b-button size="sm" @click="resetValue">{{
              $t("MAP.RESET")
            }}</b-button>
          </p>

          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="applyData"
          >
            {{ $t("COMMON.APPLY") }}
          </b-button>

          <b-button size="sm" class="float-right mr-2" @click="closeModal">
            {{ $t("COMMON.CLOSE") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import _ from "lodash";
import { LocalStore } from "@/common/LocalStore";

const ls = new LocalStore("component-map");
const radiusDefaultCircle = 10000; // in meters

export default {
  name: "Map",

  props: {
    width: String,
    height: String,

    value: Object,
  },

  data() {
    let zoomMap = ls.getItem("zoom") || 10;
    let mapCenter = ls.getItem("center") || { lat: 55.78874, lng: 49.12214 };

    return {
      radiusDefaultCircle,

      circleData: this.value,

      zoomMap,
      mapCenter,
    };
  },

  mounted() {
    document.addEventListener(
      "keyup",
      (this._handler = (ev) => {
        if (ev.key === "Delete" || ev.key === "Backspace") {
          this.resetValue();
        }
      })
    );
  },

  destroyed() {
    document.removeEventListener("keyup", this._handler);
  },

  watch: {
    value(newValue, oldValue) {
      if (!_.isEqual(newValue, oldValue)) {
        this.circleData = this.value;
      }
    },
  },

  methods: {
    onShowModal() {
      // update maps params
      let zoomMap = ls.getItem("zoom") || 10;
      let mapCenter = ls.getItem("center") || { lat: 55.78874, lng: 49.12214 };

      this.zoomMap = zoomMap;
      this.mapCenter = mapCenter;
      return this.$refs["my-modal"].show();
    },

    closeModal() {
      return this.$refs["my-modal"].hide();
    },

    resetValue() {
      this.circleData = null;
    },

    onSelectPoint(e) {
      const coordinates = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };

      this.circleData = {
        radius: radiusDefaultCircle,
        coordinates: coordinates,
      };
    },

    updateStore(key, e) {
      switch (key) {
        case "zoom":
          ls.setItem("zoom", e);
          break;
        case "reportedCenter": {
          let center = {
            lat: e.lat(),
            lng: e.lng(),
          };
          ls.setItem("center", center);
          break;
        }
      }
    },

    onCenter_changedCircle(latLng) {
      const coordinates = {
        lat: latLng.lat(),
        lng: latLng.lng(),
      };

      if (_.isEqual(this.circleData.coordinates, coordinates)) {
        return;
      }

      this.circleData.coordinates = coordinates;
    },

    onRadius_changed(e) {
      this.circleData.radius = +e.toFixed(0);
    },

    applyData() {
      this.$emit("input", this.circleData);
      this.closeModal();
    },
  },

  computed: {
    renderData() {
      if (_.isEmpty(this.circleData)) {
        return "";
      }
      const { coordinates, radius } = this.circleData;

      return `Lat: ${coordinates["lat"].toFixed(2)} Lon: ${coordinates[
        "lng"
      ].toFixed(2)}, radius ${radius} meters`;
    },
  },
};
</script>

<style scoped>
.button--close:before {
  content: none !important;
}

.action-link {
  padding-top: calc(0.65rem + 1px);
  padding-bottom: calc(0.65rem + 1px);
  line-height: calc(1.5em + 1rem + 2px);
}
</style>
