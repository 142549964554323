<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <div
        class="wizard wizard-3"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <WizardStepper
          :steps="steps"
          :current-step="step"
          :available-steps="availableSteps"
          @on-change-step="onChangeStep"
        />

        <div class="row justify-content-center py-12 px-8 py-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-7">
            <form class="kt-form" id="kt_form" @submit="submit">
              <template v-if="step === 1">
                <div
                  class="kt-wizard-v3__content"
                  data-ktwizard-type="step-content"
                  data-ktwizard-state="current"
                >
                  <div class="kt-form__section kt-form__section--first">
                    <div class="kt-wizard-v3__form">
                      <b-form-group
                        id="field-device"
                        label-cols-lg="2"
                        :label="$t('COMMON.DEVICE')"
                        label-for="field-device"
                      >
                        <multi-suggestion
                          id="deviceSuggestion"
                          suggestion-processor="deviceSuggestionProcessor"
                          :element-name="$t('DEVICE.DEVICE')"
                          :elements-name="$t('DEVICE.DEVICES')"
                          :selected-elements="dataForm.selectedDevices"
                          :addErrorNotification="true"
                          @on-element-added="onDeviceSelected"
                          @on-remove-element="removeDevice"
                        ></multi-suggestion>
                      </b-form-group>

                      <div-horizontal
                        :title="$t('DASHBOARD.OR')"
                      ></div-horizontal>

                      <b-form-group
                        label-cols-lg="2"
                        :label="$t('DASHBOARD.SELECT_ON_MAP')"
                      >
                        <Map
                          v-model="dataForm.mapData"
                          width="100%"
                          height="500px"
                        />
                      </b-form-group>

                      <div-horizontal
                        :title="$t('DASHBOARD.OR')"
                      ></div-horizontal>

                      <b-form-group
                        label-cols-lg="2"
                        :label="$t('DASHBOARD.DATA_PACKAGE')"
                      >
                        <b-form-select
                          v-model="dataForm.dataPackage"
                          :options="dataForm.dataPackages"
                          value-field="id"
                          text-field="name"
                          class="mb-3"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null" disabled
                              >--
                              {{ $t("DASHBOARD.DATA_PACKAGE") }}
                              --</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                      <div-horizontal></div-horizontal>

                      <b-form-group label-cols-lg="2" label="">
                        <b-form-checkbox
                          v-model="dataForm.realtime"
                          name="check-button"
                          switch
                        >
                          {{ $t("DASHBOARD.REALTIME") }}
                        </b-form-checkbox>
                      </b-form-group>

                      <b-form-group
                        v-if="!dataForm.realtime"
                        label-cols-lg="2"
                        :label="$t('DASHBOARD.DATE_RANGE')"
                      >
                        <range-date-picker
                          :start-date.sync="dataForm.startDate"
                          :end-date.sync="dataForm.endDate"
                          with-time
                        ></range-date-picker>
                      </b-form-group>

                      <div
                        v-if="
                          dataForm.groupType === 'NONE' ||
                          dataForm.groupType === 'SECOND'
                        "
                        class="alert alert-danger alert-elevate"
                        role="alert"
                      >
                        <div class="alert-text">
                          {{ $t("DASHBOARD.NONE_GROUP_TYPE_WARNING") }}
                        </div>
                      </div>
                      <b-form-group
                        v-if="dataForm.realtime"
                        label-cols-lg="2"
                        :label="$t('API.REAL_TIME_RANGE')"
                      >
                        <b-form-select v-model="dataForm.interval">
                          <option
                            v-for="opt in this.realTimeIntervalOptions"
                            :key="opt"
                            :value="opt"
                          >
                            {{ opt }}
                          </option>
                        </b-form-select>
                      </b-form-group>

                      <b-form-group
                        label-cols-lg="2"
                        :label="$t('API.GROUP_TYPE')"
                      >
                        <b-form-select v-model="dataForm.groupType">
                          <option
                            v-for="opt in groupTypeOptions"
                            :key="opt"
                            :value="opt"
                          >
                            {{ opt }}
                          </option>
                        </b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="step === 2">
                <b-input
                  :placeholder="$t('DASHBOARD.WIDGET_NAME')"
                  v-model.trim="dataForm.widgetName"
                >
                </b-input>
                <div-horizontal></div-horizontal>
                <div
                  class="kt-wizard-v3__content"
                  data-ktwizard-type="step-content"
                  data-ktwizard-state="current"
                >
                  <div class="kt-form__section kt-form__section--first">
                    <div class="kt-wizard-v3__form">
                      <div
                        v-for="widget in availableWidgets"
                        :key="widget.key"
                        class="charts--type"
                        @click="dataForm.chartType = widget.code"
                        :class="{
                          'charts--type__selected':
                            dataForm.chartType === widget.code,
                        }"
                        :data-content="$t(widget.i18nString)"
                      >
                        <img
                          :src="
                            require('@/assets/media/charts/' + widget.picUrl)
                          "
                          title=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="step === 3">
                <wrap-loading :action="loadParameters">
                  <div
                    class="kt-wizard-v3__content"
                    style="padding: 10px"
                    data-ktwizard-type="step-content"
                    data-ktwizard-state="current"
                  >
                    <div class="kt-form__section kt-form__section--first">
                      <div class="kt-wizard-v3__form">
                        <b-form-group
                          v-if="!displayRadioButtons"
                          :label="$t('WIDGET.PARAMETERS')"
                        >
                          <b-form-checkbox
                            v-if="variantParameters.length !== 0"
                            @change="onSelectAllParams"
                            v-model="allParameters"
                          >
                            {{ $t("WIDGET.SELECT_ALL_PARAMETERS") }}
                          </b-form-checkbox>
                          <div-horizontal />
                          <b-form-checkbox
                            v-for="option in variantParameters"
                            v-model="dataForm.selectedParameters"
                            @change="onSelectParam"
                            :key="option.id"
                            :value="option.id"
                            name="flavour-3a"
                          >
                            {{ option.name }} ({{ option.code }})
                          </b-form-checkbox>
                        </b-form-group>

                        <b-form-group v-else :label="$t('WIDGET.PARAMETERS')">
                          <b-form-radio
                            v-for="option in variantParameters"
                            v-model="dataForm.parameter"
                            :key="option.id"
                            :value="option.id"
                            name="parameter"
                            >{{ option.name }} ({{ option.code }})
                          </b-form-radio>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </wrap-loading>
              </template>

              <div class="d-flex justify-content-between border-top pt-10">
                <button
                  @click="onCancel"
                  type="button"
                  class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                >
                  {{ $t("COMMON.CANCEL") }}
                </button>

                <button
                  type="button"
                  v-if="step === availableSteps.length"
                  :disabled="!availableSubmit"
                  class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-next"
                  @click="submit"
                >
                  {{
                    edit
                      ? $t("DASHBOARD.SAVE_WIDGET")
                      : $t("DASHBOARD.CREATE_WIDGET")
                  }}
                </button>
                <button
                  type="button"
                  v-else
                  class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                  data-wizard-type="action-next"
                  :disabled="!availableSteps.includes(step + 1)"
                  @click="goToNextStep"
                >
                  {{ $t("COMMON.NEXT_STEP") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <debug>{{ dataForm }}</debug>
  </div>
</template>

<style lang="scss">
@import "~@/assets/sass/pages/wizard/wizard-3.scss";

.charts--type {
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-shadow: 1px 1px 10px #f2f2f2;
  border: 1px solid transparent;
  margin-bottom: 35px;
  margin-right: 10px;
  height: 180px;
  width: calc(33% - 10px);

  &__selected {
    background-color: rgba(204, 204, 204, 0.1);
    border: 1px solid rgba(204, 204, 204, 1);
  }

  &:after {
    font-size: 120%;
    position: absolute;
    content: attr(data-content);
    left: 0;
    text-align: center;
    width: 100%;
    bottom: -30px;
  }

  img {
    object-fit: scale-down;
    width: 100%;
    height: 100%;
  }
}

.count--devices {
  opacity: 0.3 !important;

  &__active {
    opacity: 1 !important;
  }
}
</style>

<script>
import _ from "lodash";
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Map from "@/view/content/Map";
import DivHorizontal from "@/view/content/div-horizontal";
import WizardStepper from "@/view/content/WizardStepper";
import { GroupTypeOptions, RealTimeIntervalOptions } from "@/common/config";
import { Actions } from "@/core/services/store/dashboard/dashboard.module";
import WrapLoading from "@/view/content/wrap-loading.vue";
import { ChartType } from "@/core/services/store/dashboard/dashboard.model";
import ApiService from "@/core/services/api.service";
import MultiSuggestion from "../component/MultiSuggestion";
import RangeDatePicker from "../component/RangeDatePicker";

export default Vue.extend({
  name: "DashboardWidgetEditor",
  components: {
    RangeDatePicker,
    WrapLoading,
    WizardStepper,
    DivHorizontal,
    Map,
    MultiSuggestion,
  },

  data() {
    return {
      ChartTypes: ChartType,

      filterDevice: "",
      parameterName: "",

      step: 1,
      steps: [
        { id: 1, title: this.$t("DASHBOARD.CHOOSE_DATA_DEVICE") },
        { id: 2, title: this.$t("DASHBOARD.WIDGET_OPTIONS") },
        { id: 3, title: this.$t("COMMON.PARAMETERS") },
      ],

      availableWidgets: null,

      dataForm: {
        selectedDevices: [],
        mapData: null,
        dataPackages: [],

        dataPackage: null,

        startDate: null,
        endDate: null,

        groupType: "AUTO",
        chartType: ChartType.Undefined,
        interval: 24,

        widgetName: "",

        selectedParameters: [],

        parameter: null,

        realtime: false,
      },

      edit: false,
      widgetIndex: null,

      // loading when is step==3
      variantParameters: [],
      allParameters: false,

      // todo: take out into configs.
      groupTypeOptions: GroupTypeOptions,
      realTimeIntervalOptions: RealTimeIntervalOptions,
    };
  },

  async created() {
    this.widgetIndex = this.$route.params.index;
    if (this.widgetIndex !== undefined && this.widgetIndex !== null) {
      let widget = this.dashboardSettings.widgets[this.widgetIndex];
      // проверка, тот ли виджет вытянули
      if (widget.i !== this.$route.params.id) {
        return;
      }

      if (widget.measureParams.deviceIds.length !== null) {
        // если выбраны устройства
        this.dataForm.selectedDevices.push(...widget.measureParams.deviceIds);
      }
      // тут добавить если указано на карте
      else if (widget.measureParams.dataPackageId !== null) {
        // если выбран пакет данных
        this.dataForm.dataPackage = widget.measureParams.dataPackageId;
      } else {
        // может быть добавить уведомление "что то пошло не так"
        return;
      }

      if (widget.measureParams.realtime === true) {
        this.dataForm.realtime = true;
      } else {
        this.dataForm.startDate = new Date(widget.measureParams.startDate);
        this.dataForm.endDate = new Date(widget.measureParams.endDate);
      }

      this.dataForm.widgetName = widget.measureParams.widgetName;
      this.dataForm.chartType = widget.type;
      this.dataForm.groupType = widget.measureParams.groupType;

      this.dataForm.selectedParameters = widget.measureParams.parameterIds;

      this.edit = true;
      await this.updateAvailableWidgets();
    }
  },

  mounted() {
    const inputFirstFocus =
      this.$el.querySelector < HTMLInputElement > "#device input";

    if (inputFirstFocus) {
      inputFirstFocus.focus();
    }

    this.loadDataPackages();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.DASHBOARD"), route: "/dashboard" },
      {
        title: this.edit
          ? this.$t("DASHBOARD.EDIT_WIDGET")
          : this.$t("DASHBOARD.ADD_NEW_WIDGET"),
      },
    ]);
  },

  watch: {
    "dataForm.mapData"(data) {
      if (data) {
        this.dataForm.selectedDevices = [];
        this.dataForm.dataPackage = null;
      }
    },

    "dataForm.dataPackage"(data) {
      if (data) {
        this.dataForm.selectedDevices = [];
        this.dataForm.mapData = null;
      }
    },
  },

  methods: {
    async updateAvailableWidgets() {
      return this.axios
        .get("/api/widget/getAvailableWidgets", {
          params: {
            teamId: ApiService.teamData.value,
            deviceIds: this.dataForm.selectedDevices.map((s) => s.id),
          },
        })
        .then((d) => {
          this.availableWidgets = d.data || [];
        });
    },

    onSelectParam() {
      //console.log("onSelectParam");
      this.$nextTick(() => {
        if (
          this.dataForm.selectedParameters.length ===
          this.variantParameters.length
        ) {
          this.allParameters = true;
        } else {
          this.allParameters = false;
        }
      });
    },
    onSelectAllParams() {
      //console.log("onSelectAllParams");
      this.$nextTick(() => {
        if (this.allParameters === false) {
          this.dataForm.selectedParameters.splice(0);
        } else {
          if (
            this.variantParameters.length !== 0 &&
            this.dataForm.selectedParameters.length ===
              this.variantParameters.length
          ) {
            return;
          }
          this.dataForm.selectedParameters.splice(0);
          for (let i = 0; i < this.variantParameters.length; i++) {
            this.dataForm.selectedParameters.push(this.variantParameters[i].id);
          }
        }
      });
    },
    onCancel() {
      this.$router.replace({ name: "dashboard" });
    },

    getLang() {
      return localStorage.getItem("language") || "en";
    },

    async loadDataPackages() {
      const q = "/api/data-package/available";
      const requestParams = {
        teamId: ApiService.teamData.value,
        onlyPurchased: true,
      };

      await this.axios
        .get(q, { params: requestParams })
        .then((response) =>
          response.data.forEach((data) => this.dataForm.dataPackages.push(data))
        );
      //console.log(this.dataForm.dataPackages);
      /*console.log(data);
      this.dataPackages.push(data[0]);// = data || [];*/
    },

    async loadParameters() {
      const q = "/api/measure/available-parameters";
      const requestParams = {
        deviceIds: _.map(this.dataForm.selectedDevices, (device) => device.id),
        teamId: ApiService.teamData.value,
        startDate: this.dataForm.startDate,
        endDate: this.dataForm.endDate,
      };
      if (this.dataForm.realtime) {
        delete requestParams["startDate"];
        delete requestParams["endDate"];
      }

      if (this.dataForm.mapData) {
        const {
          radius,
          coordinates: { lat, lng },
        } = this.dataForm.mapData;

        requestParams["radius"] = radius;
        requestParams["latitude"] = lat;
        requestParams["longitude"] = lng;
      }

      if (this.dataForm.dataPackage) {
        requestParams["dataPackageId"] = this.dataForm.dataPackage;
      }

      if (this.isCircleChart) {
        requestParams["circle"] = true;
      }

      if (this.isMapChart) {
        requestParams["concentration"] = true;
      }

      const { data } = await this.axios.get(q, { params: requestParams });
      this.variantParameters = data || [];

      if (this.edit) {

        // костылек на выбор всех параметров. так как в виджете могут быть любые параметры, даже не доступные для текущего отображения, то надо проверить только наличие каждого из доступных параметров в выбраных параметрах виджета
        this.allParameters = true;
        for (let i = 0; i < this.variantParameters.length; i++) {
          if (
            this.dataForm.selectedParameters.indexOf(
              this.variantParameters[i].id
            ) === -1
          ) {
            this.allParameters = false;
            break;
          }
        }
        if (this.displayRadioButtons) {
          this.dataForm.parameter = this.dataForm.selectedParameters[0];
        }
      }
    },

    async onDeviceSelected(data) {
      //@ts-ignore
      this.dataForm.selectedDevices.push(data);
      this.availableWidgets = null;
      await this.updateAvailableWidgets();

      this.$nextTick(() => {
        this.dataForm.mapData = null;
        this.dataForm.dataPackage = null;
      });
    },

    async submit(e) {
      e.preventDefault();
      if (
        (this.availableSteps.length !== 3 &&
          this.dataForm.chartType !== ChartType.Grid) ||
        !this.availableSubmit
      ) {
        return;
      }

      if (this.displayRadioButtons) {
        this.dataForm.selectedParameters = [];
        this.dataForm.selectedParameters.push(this.dataForm.parameter);
      }

      //  create new WidgetModel and set into dashboard store.
      //  then return
      const params = {
        deviceIds: _.map(this.dataForm.selectedDevices, (device) => {
          return { id: device.id, name: device.name };
        }),
        dataPackageId: this.dataForm.dataPackage,
        parameterIds: this.dataForm.selectedParameters,
        parameter: this.dataForm.parameter || undefined,
        startDate: this.dataForm.startDate,
        endDate: this.dataForm.endDate,
        realtime: this.dataForm.realtime,
        groupType: this.dataForm.groupType,
        widgetName: this.dataForm.widgetName,
      };
      if (this.dataForm.realtime) {
        params["interval"] = this.dataForm.interval;
        delete params["startDate"];
        delete params["endDate"];
      }

      if (this.edit) {
        const payload = {
          h: this.dashboardSettings.widgets[this.widgetIndex].h,
          w: this.dashboardSettings.widgets[this.widgetIndex].w,
          x: this.dashboardSettings.widgets[this.widgetIndex].x,
          y: this.dashboardSettings.widgets[this.widgetIndex].y,
          i: this.dashboardSettings.widgets[this.widgetIndex].i,
          type: this.dataForm.chartType,
          params,
        };

        await this.$store.dispatch(Actions.EditWidget, payload);
      } else {
        const payload = {
          type: this.dataForm.chartType,
          params,
        };
        await this.$store.dispatch(Actions.SaveWidget, payload);
      }
      await this.$router.replace({ name: "dashboard" });
    },

    async removeDevice(index, data) {
      this.dataForm.selectedDevices.splice(index, 1);
      this.availableWidgets = null;
      await this.updateAvailableWidgets();
    },

    onChangeStep(step) {
      this.step = step;
    },

    goToNextStep() {
      if(this.step == 1) {
        let name = "";
        if(this.dataForm.selectedDevices.length != 0) {
          const devices = this.dataForm.selectedDevices.map(d => d.name);
          name = devices.join(", ");
        } else if(this.dataForm.dataPackage != null) {
          name = this.dataForm.dataPackage.name;
        } else if(this.dataForm.mapData != null) {
          name = `${this.dataForm.mapData.coordinates.lat.toFixed(2)}, ${this.dataForm.mapData.coordinates.lng.toFixed(2)}`;
        }
        this.dataForm.widgetName = name;
      }
      this.step++;
    },

    setStartDateTime(time) {
      const date = this.dataForm.startDate;
      if (!date) return;

      if (!time) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        this.dataForm.startDate = new Date(date);
        return;
      }
      const [h, min, sec] = time.split(":").map(Number);
      date.setHours(h);
      date.setMinutes(min);
      date.setSeconds(sec);

      this.dataForm.startDate = new Date(date);
    },

    setEndDateTime(time) {
      const date = this.dataForm.endDate;
      if (!date) return;

      if (!time) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        this.dataForm.endDate = new Date(date);
        return;
      }
      const [h, min, sec] = time.split(":").map(Number);
      date.setHours(h);
      date.setMinutes(min);
      date.setSeconds(sec);

      this.dataForm.endDate = new Date(date);
    },
  },

  computed: {
    availableSteps() {
      let res = [1];

      if (
        (this.dataForm.selectedDevices.length > 0 ||
          !_.isEmpty(this.dataForm.mapData) ||
          this.dataForm.dataPackage != null) &&
        ((this.dataForm.startDate && this.dataForm.endDate) ||
          this.dataForm.realtime) &&
        this.dataForm.groupType &&
        (this.availableWidgets != null || !_.isEmpty(this.dataForm.mapData))
      ) {
        res.push(2);
      }

      if (this.dataForm.chartType !== ChartType.Grid) {
        res.push(3);
      }

      return res;
    },

    availableSubmit() {
      return (
        this.dataForm.selectedParameters.length > 0 ||
        !!this.dataForm.parameter ||
        this.dataForm.chartType === ChartType.Grid
      );
    },

    isCircleChart() {
      return (
        this.dataForm.chartType === ChartType.Radar ||
        this.dataForm.chartType === ChartType.Pie
      );
    },

    displayRadioButtons() {
      return (
        this.dataForm.chartType === ChartType.Radar ||
        this.dataForm.chartType === ChartType.Pie ||
        this.dataForm.chartType === ChartType.Map
      );
    },

    isMapChart() {
      return this.dataForm.chartType === ChartType.Map;
    },

    dashboardSettings() {
      return this.$store.state.dashboard.settings;
    },
  },
});
</script>
