<template>
  <b-overlay :show="loading" rounded="sm">
    <slot :data="data" />
  </b-overlay>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      data: null,
      error: null,
    };
  },

  props: {
    action: [Promise, Function],
  },

  async mounted() {
    this.loading = true;
    try {
      this.data = "then" in this.action ? this.action : await this.action();
    } catch (err) {
      this.$log.error(err);
      this.error = err;
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
